import React, { memo, useEffect } from "react";

function GenerateInputFields(props) {
  const { formFields, marginBetweenFiled = 4,setIsDeliveryFieldsVisible, allCollections , showFields } = props;
  useEffect(()=>{
    formFields.map((singleField)=>{
      if(singleField.id ==='shippingType' && singleField.options.length ===1){
        let customEvent ={
          target:{
            id:singleField.id,
            value:singleField.options[0]
          }
        }
        handleInputChange(customEvent)
      }
    })
  },[])
  const handleInputChange = async(event) => {
    if (event.target.id === 'shippingType') {
      await setIsDeliveryFieldsVisible(true);
      const selectedOption = event.target.value;
      resetAddressField();
      if (allCollections && selectedOption !== 'Home delivery') {
        allCollections.CollectionIndex.forEach((singleCollection) => {
          singleCollection.deliveryOptions.forEach((deliveryOption) => {
            if (deliveryOption.type === selectedOption) {
              updateAddressFields(deliveryOption.address);
            }
          });
        });
      }
    }
  };

  const resetAddressField = () => {
    if (allCollections) {
      allCollections.CollectionIndex.forEach((singleCollection) => {
        singleCollection.deliveryOptions.forEach((deliveryOption) => {
          clearAddressFields(deliveryOption.address);
        });
      });
    }
  };

  const updateAddressFields = (address) => {
    Object.entries(address).forEach(([key, value]) => {
      const formElement = document.getElementById(key);
      if (formElement) {
        formElement.value = value;
        formElement.classList.add('btn-disabled');
        formElement.setAttribute('readonly', true);
      }
    });
  };

  const clearAddressFields = (address) => {
    Object.entries(address).forEach(([key]) => {
      const formElement = document.getElementById(key);
      if (formElement) {
        formElement.value = '';
        formElement.classList.remove('btn-disabled');
        formElement.removeAttribute('readonly');
      }
    });
  };


  return (
    <div className="row mb-2">
      {formFields.map((field, index) => (
        <div className={`col-${field.area} mb-${marginBetweenFiled}`} key={field.id}>
          {field.type === 'text' || field.type === 'tel' || field.type === 'number' || field.type === 'email' ? (
            <>
              {field.showLabel ? <label htmlFor={field.name}>{field.label} {field.isRequired ? "*" : null}</label> : null}
              <input
                className={`form-control ${field.isDisabled ? 'btn-disabled' : ''}`}
                name={field.name}
                id={field.id}
                type={field.type}
                required={field.isRequired}
                maxLength={field.maxLength}
                placeholder={`${field.showPlaceHolder ? field.placeholder : ''} ${field.isRequired && field.showPlaceHolder ? '*' : ''}`}
              />
            </>
          ) : field.type === 'pickList' ?
            <>
              {field.showLabel ? <label htmlFor={field.name}>{field.label} {field.isRequired ? "*" : null}</label> : null}
              <select
                id={field.id}
                onChange={handleInputChange}
                className='form-control '
                name={field.name}
                defaultValue={field.value}
                required={field.isRequired}>
               {field.options.length>1? <option value='' disabled>
                  {field.placeholder}
                </option>:''}
                {field.options.map((entry, index) => (
                  <option
                    key={index}
                    value={entry}>
                    {entry}
                  </option>
                ))}
              </select>
            </>
            :
            field.type === 'textarea' ?
              <>
                {field.showLabel ? <label htmlFor={field.name}>{field.label} {field.isRequired ? "*" : null}</label> : null}
                <textarea
                  className="form-control"
                  required={field.isRequired}
                  id={field.id}
                  name={field.name}
                  placeholder={`${field.showPlaceHolder ? field.placeholder : ''} ${field.isRequired && field.showPlaceHolder ? '*' : ''}`}
                ></textarea>
              </>
              : null}
        </div>
      ))}
    </div>
  );
}

export default memo(GenerateInputFields);
