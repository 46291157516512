import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";

import ALink from "../../custom-link";

import { modalActions } from "../../store/modalReducer";

import { toDecimal } from "../../utils";

function DescOne(props) {
  const { product, isGuide = true, isDivider = true, openModal } = props;
  useEffect(() => {
    console.log('Product Logs==>', product);
  })
  return (
    <Tabs
      className="tab tab-nav-simple product-tabs"
      selectedTabClassName="show"
      selectedTabPanelClassName="active"
      defaultIndex={0}
    >
      <TabList className="nav nav-tabs justify-content-center" role="tablist">
        {
          Object.entries(product.description).map(([key,value]) => (
            <Tab className="nav-item">
              <span className="nav-link">{value.tab_heading}</span>
            </Tab>
          ))
        }
      </TabList>
      <div className="tab-content">
        <TabPanel className="tab-pane product-tab-description">
          <div className="row mt-6">
            <div className="col-md-12">
              <h5 className="description-title mb-4 font-weight-semi-bold ls-m">
                {product.description.tab1.feature.heading}
              </h5>
              <p className="mb-2">
              {product.description.tab1.feature.description}
              </p>
              <ul className="mb-8">
                {
                  product.description.tab1.feature.details.map((detail) => (
                    <li>{detail}</li>
                  ))
                }
              </ul>
              <h5 className="description-title mb-3 font-weight-semi-bold ls-m">
              {product.description.tab1.specifications.heading}
              </h5>
              <table className="table">
                <tbody>
                  {
                    Object.entries(product.description.tab1.specifications).map(([key, value]) => (
                      <tr>
                        <th className="font-weight-semi-bold text-dark pl-0">
                          {key}
                        </th>
                        <td className="pl-4">{value}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
        {/* {
          product && product[0].brands.length > 0 || colors.length > 0 || sizes.length > 0 ?
            <TabPanel className="tab-pane product-tab-additional">
              <ul className="list-none">
                {
                  product[0].categories.length > 0 ?
                    <li><label>Categories:</label>
                      <p>
                        {product[0].categories.map((item, index) => (
                          <React.Fragment key={item.name + '-' + index}>
                            {item.name}
                            {index < product[0].categories.length - 1 ? ', ' : ""}
                          </React.Fragment>
                        ))}
                      </p>
                    </li> : ""
                }

                {
                  product[0].brands.length > 0 ?
                    <li><label>Brands:</label>
                      <p>
                        {product[0].brands.map((item, index) => (
                          <React.Fragment key={item.name + '-' + index}>
                            {item.name}
                            {index < product[0].brands.length - 1 ? ', ' : ""}
                          </React.Fragment>
                        ))}
                      </p>
                    </li> : ""
                }

                {
                  colors.length > 0 ?
                    <li><label>Color:</label>
                      <p>
                        {colors.map((item, index) => (
                          <React.Fragment key={item.name + '-' + index}>
                            {item.name}
                            {index < colors.length - 1 ? ', ' : ""}
                          </React.Fragment>
                        ))}
                      </p>
                    </li> : ""
                }

                {
                  sizes.length > 0 ?
                    <li><label>Size:</label>
                      <p>
                        {
                          sizes.map((item, index) => (
                            <React.Fragment key={item.name + '-' + index}>
                              {item.name}
                              {index < sizes.length - 1 ? ', ' : ""}
                            </React.Fragment>
                          ))}
                      </p>
                    </li> : ""
                }
              </ul>
            </TabPanel>
            : ''
        } */}
        <TabPanel className="tab-pane product-tab-shipping-returns">
          <h6 className="mb-2">{product.description.tab2.heading}</h6>
          <p className="mb-0">
            {
              product.description.tab2.description
            }
          </p>
        </TabPanel>
      </div>
    </Tabs>
  );
}

export default connect("", { openModal: modalActions.openModal })(DescOne);
