import React, { useEffect, useState, memo } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import GenerateInputFields from "../../pages/GenerateInputFields";
import MediaOne from "./utils/Media/MediaOneForGental";
import { useParams } from "react-router-dom";
import Quantity from "../../utils/quantity/Quantity";
import { cartActions } from "../../store/cart";
import ALink from "../../custom-link";
import { fadeIn } from "../../utils/keyframes";
import Reveal from 'react-awesome-reveal';
import Spinner from "../../spinner/Spinner";
import DescOne from "../description/DescOne";
import { getDataFromDatabase } from "../../utils/dataBaseHelper";
function ProductHome(props) {
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState([]);
  const [isProductLoaded, setIsProductLoaded] = useState(false);
  const [isFullPageSpinnerActive, setIsFullPageSpinnerActive] = useState(true);
  const { productSlug } = useParams();
  useEffect(() => {
    const fetchProductAndCollectionIds = async () => {
      const response = await getDataFromDatabase(`/database/products/${productSlug}.json`);
      if (response) {
        setProduct(response);
        setIsProductLoaded(true);
        setIsFullPageSpinnerActive(false);
      }
    };
    fetchProductAndCollectionIds();
  }, [])
  const handleAddToCartSubmit = (event) => {
    const formElement = document.getElementById('productForm');
    event.preventDefault();
    props.addToCart({ ...product, productInstanceId: createUniqueNameForProduct(formElement), qty: quantity, price: product.onePrice, formFields: populateFormFieldsWithValuesAfterGettingFilled(formElement) });
    formElement.reset()
  };
  const populateFormFieldsWithValuesAfterGettingFilled = (formElement) => {
    const formData = new FormData(formElement);
    let valueFilledInByUserByFormFieldName = Object.fromEntries(formData.entries())
    // product.formFields.map((singleField) => {
    //   singleField.value = valueFilledInByUserByFormFieldName[singleField.name]
    // })
    // return product.formFields;
    let updatedFormFieldsWithValues = product.formFields.map((singleField) => ({
      ...singleField,
      value: valueFilledInByUserByFormFieldName[singleField.name]
    }))
    return updatedFormFieldsWithValues;
  }
  const createUniqueNameForProduct = (formElement) => {
    let uniqueName = {};
    uniqueName.productName = product.name;
    const formData = new FormData(formElement);
    uniqueName.formDetails = Object.fromEntries(formData.entries());
    return JSON.stringify(uniqueName);
  }
  function changeQty(qty) {
    setQuantity(qty);
  }
  if (!product) {
    return <p className="container mt-2">Product Not Found</p>;
  }
  return (
    <>
      {
        isProductLoaded?
        <>
          <Helmet>
            <title>Product | {product.name}</title>
          </Helmet>
          <div className="page-content mb-10 pb-6">
            <div className="container vertical">
              <div className="product product-single row mb-2">
                <div className="col-md-6 mt-4 sticky-sidebar-wrapper">
                  <MediaOne product={[product]} />
                </div>
                <div className="col-md-6">
                  <nav className="breadcrumb-nav">
                    <div className="container pl-1">
                      <ul className="breadcrumb">
                        <li><ALink href="/"><i className="d-icon-home"></i></ALink></li>
                        <li>{product.name}</li>
                      </ul>
                    </div>
                  </nav>
                  <form className="pl-lg-2" onSubmit={handleAddToCartSubmit} id='productForm'>
                    <main className="main contact-us">
                      <div className="page-content pt-3">
                        <Reveal keyframes={fadeIn} delay="50" duration="1000" triggerOnce>
                          <section className="contact-section">
                            <div className="w-100">
                              <h4 className="ls-m font-weight-bold">{product.name}</h4>
                              <p>{product.short_description}</p>
                              <h5>&euro;{product.onePrice}</h5>
                              <GenerateInputFields
                                formFields={product.formFields}
                              />
                            </div>
                          </section>
                        </Reveal>
                      </div>
                    </main>
                    <div className="product-form product-qty pb-0">
                      <div className="product-form-group">
                        <Quantity qty={quantity} max={product.stock} product={product} onChangeQty={changeQty} />
                        <button type="submit" className={`btn-product btn-cart text-normal ls-normal font-weight-semi-bold`}>
                          <i className='d-icon-bag'></i>Add to Cart
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <DescOne product={product}/> */}
            </div>
          </div>
        </>:isFullPageSpinnerActive?<Spinner/>:null
      }

    </>
  );
}

function mapStateToProps(state) {
  return {
    wishlist: state.wishlist.data ? state.wishlist.data : [],
  };
}

export default connect(mapStateToProps, {
  addToCart: cartActions.addToCart,
})(memo(ProductHome));
