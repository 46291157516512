import React, { useState, useEffect } from 'react';
import Reveal from 'react-awesome-reveal';
import OwlCarousel from '../../owl-carousel/owl-carousel';
import ProductTwo from '../../products/ProductTwo';
import { featuredSlider } from '../../owl-carousel/data/carousel';
import { fadeIn } from '../../utils/keyframes';
import ALink from '../../custom-link';
import axios from 'axios';
import { getDataFromDatabase } from '../../utils/dataBaseHelper';
function FeaturedCollection(props) {
    let { collectionSlug } = props;
    const [products, setProducts] = useState([])
    const [collectionList, setCollectionList] = useState([]);
    const [isProductLoading, setProductLoading] = useState(true);
    useEffect(() => {
        const fetchCollectionData = async () => {
            try {
                const collections = await getDataFromDatabase(`/database/search/allCollection.json`);
                if (collections && collections.CollectionIndex) {
                    const activeCollections = collections.CollectionIndex.filter(
                        singleCollection => singleCollection.collectionSlug === collectionSlug.collectionSlug && singleCollection.isActive
                    );
                    const collectionDataPromises = activeCollections.map(singleCollection =>
                        getDataFromDatabase(`/database/collections/${singleCollection.collectionSlug}.json`)
                    );
                    const collectionsData = await Promise.all(collectionDataPromises);
                    const finalCollections = collectionsData.filter(collection => collection);
                    setCollectionList(finalCollections);
                    const productPromises = collectionsData.flatMap(collection =>
                        collection.products.map(product => {
                            const url = `/database/products/${product}.json`;
                            return getDataFromDatabase(url);
                        })
                    );
                    const productsResponses = await Promise.all(productPromises);
                    if(productsResponses){
                        setProducts(productsResponses);
                    }
                    setProductLoading(false);
                }
            } catch (error) {
                console.error("Error fetching collections:", error);
            }
        };
        fetchCollectionData();
    }, []);


    return (
        <div className=''>
            {collectionList.length > 0 ? collectionList.map((oneCollection) => (
                <section className="pt-7 pb-1" key={oneCollection.collectionSlug} >
                    <ALink href={`collections/${oneCollection.collectionSlug}`}>
                        <h2 className="title title-simple ls-m">{oneCollection.collectionName}</h2>
                    </ALink>

                    <Reveal triggerOnce>
                        {isProductLoading ? (
                            <OwlCarousel adClass="owl-theme owl-nav-full" options={featuredSlider}>
                                {[1, 2, 3, 4, 5].map((item) => (
                                    <div className="product-loading-overlay" key={'featured-skel-' + item}></div>
                                ))}
                            </OwlCarousel>
                        ) : (
                            <OwlCarousel adClass="owl-theme owl-nav-full" options={featuredSlider}>
                                {products && products.map((item, index) => (
                                    <ProductTwo
                                        adClass="text-left"
                                        product={item}
                                        key={`featured-product-${index}`}
                                        collectionSlug={oneCollection.collectionSlug}
                                        collectionData ={oneCollection}
                                    />
                                ))}
                            </OwlCarousel>
                        )}
                    </Reveal>
                </section>
            )) : null}
        </div>
    );
}

export default React.memo(FeaturedCollection);
