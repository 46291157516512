import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import ShopBanner from '../../shop/ShopBanner';
import SidebarFilterOne from '../../shop/slideBar/SidebarFilterOne';
import ProductListOne from '../../shop/product-list/product-list-one';
import { useParams, useSearchParams } from "react-router-dom";
import Spinner from '../../spinner/Spinner';
import { getDataFromDatabase } from '../../utils/dataBaseHelper';
function CollectionView() {
    const { collectionSlug, productSlug } = useParams();
    const [collectionData, setCollectionData] = useState({});
    const [isFullPageSpinnerActive, setFullPageSpinnerActive] = useState(true);
    useEffect(() => {
        const fetchProductAndCollectionIds = async () => {
            const collections = await getDataFromDatabase(`/database/collections/${collectionSlug}.json`);
            if (collections) {
                setCollectionData(collections)
            }
        }
        fetchProductAndCollectionIds();
    }, [])
    const [itemData, setItemData] = useState({})
    const filteredData = (itemData) => {
        setItemData(itemData)

    }

    return (
        <main className="main">
            <Helmet>
                <title>Collection | {String(collectionData.collectionName)}</title>
            </Helmet>
            <ShopBanner subTitle="" title={collectionData.collectionName} current={`${collectionData.collectionSlug}` } bannerImageUrl={collectionData.bannerImage} />

            <div className="page-content mb-lg-10 mb-0 pb-lg-6 pb-0">
                <div className="container">
                    <div className="row main-content-wrap gutter-lg">
                        <div className="main-content">
                            <ProductListOne itemsPerRow={4} type="off-canvas" collectionData = {collectionData} itemData={itemData} isToolbox={false} />
                        </div>
                    </div>
                </div>
            </div>
        </main >

    )
}

export default React.memo(CollectionView);