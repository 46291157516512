import React, { useEffect, useState } from "react";
import axios from "axios";
import { getDataFromDatabase } from "../utils/dataBaseHelper";
export default function CookieConsentBanner() {
  const [isCookieConsentDisplayed, setIsCookieConsentDisplayed] = useState(false);
  const [companyProfile, setCompanyProfile] = useState({});
  const [cookieName, setCookieName] = useState('');
  const [cookieAcceptanceExpirationDays, setCookieAcceptanceExpirationDays] = useState(0);
  const [cookieRejectionExpirationDays, setCookieRejectionExpirationDays] = useState(0);
  useEffect(() => {
    const fetchCompanyProfileMetaData = async () => {
      const companyProfileResponse = await getDataFromDatabase('/database/companyProfile/companyProfile.json');
      if (companyProfileResponse) {
        setCompanyProfile(companyProfileResponse);
        setCookieName(`${companyProfileResponse["cookie-consent"]["cookie-name"]}-CookieConsent`);
        setCookieAcceptanceExpirationDays(companyProfileResponse["cookie-consent"]["consent-acceptance-expire-days"]);
        setCookieRejectionExpirationDays(companyProfileResponse["cookie-consent"]["consent-rejection-expire-days"]);
      }
    };
    fetchCompanyProfileMetaData();
  }, []);

  useEffect(() => {
    if (cookieName) {
      checkConsignedCookieExistOrNot();
    }
  }, [cookieName]);

  const checkConsignedCookieExistOrNot = () => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    if (!cookies[cookieName]) {
      setIsCookieConsentDisplayed(true);
    }
  };

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const handleConsentAccept = () => {
    setCookie(cookieName, 'true', cookieAcceptanceExpirationDays);
    setIsCookieConsentDisplayed(false);
    console.log("Cookies accepted");
  };

  const handleConsentReject = () => {
    setCookie(cookieName, 'false', cookieRejectionExpirationDays);
    setIsCookieConsentDisplayed(false);
    console.log("Cookies rejected");
  };

  if (!isCookieConsentDisplayed) return null;

  return (
    <div className="minipopup-area" style={{ position: 'fixed', bottom: '10px' }}>
      <div className="minipopup-box show" style={{ padding: '20px', background: '#fff', borderTop: '1px solid #ddd' }}>
        <p className="minipopup-title">
          {companyProfile["cookie-consent"]?.["consent-heading"] || 'Cookie Consent'}
        </p>
        <div className="product product-purchased product-cart mb-0">
          <p>{companyProfile["cookie-consent"]?.["consent-description"] || 'We use cookies to enhance your experience.'}</p>
        </div>
        <div className="action-group d-flex">
          <button
            onClick={handleConsentAccept}
            className="btn btn-sm btn-primary btn-rounded mr-3"
          >
            Accept
          </button>
          <button
            onClick={handleConsentReject}
            className="btn btn-sm btn-outline btn-primary btn-rounded"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
}
