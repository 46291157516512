import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ALink from "../custom-link";
import Quantity from "../utils/quantity/Quantity";
import { cartActions } from "../store/cart";
import { toDecimal, getTotalPrice } from "../utils";
import { Helmet } from "react-helmet";
import Navigation from "../cartNavigation/navigation";
import axios from "axios";
import { getDataFromDatabase } from "../utils/dataBaseHelper";
function Cart(props) {
  const { cartList, removeFromCart, updateCart } = props;
  const [cartItems, setCartItems] = useState([]);
  const [companyProfile, setCompanyProfile] = useState([])
  useEffect(() => {
    const fetchCompanyProfileMetaData = async () => {
      const companyProfileResponse = await getDataFromDatabase('/database/companyProfile/companyProfile.json');
      if (companyProfileResponse) {
        setCompanyProfile(companyProfileResponse);
      }
    };
    fetchCompanyProfileMetaData();
  }, [])

  useEffect(() => {
    setCartItems([...cartList]);
  }, [cartList]);
  const onChangeQty = (productInstanceId, qty) => {
    const updatedItems = cartItems.map((item) =>
      item.productInstanceId === productInstanceId ? { ...item, qty: qty } : item
    );
    setCartItems(updatedItems);
    updateCart(updatedItems);
  };

  const compareItems = () => {
    if (cartItems.length !== cartList.length) return false;

    for (let index = 0; index < cartItems.length; index++) {
      if (cartItems[index].qty !== cartList[index].qty) return false;
    }

    return true;
  };

  const update = () => {
    updateCart(cartItems);
  };

  return (
    <>
      <Helmet>
        <title>{String(companyProfile['company-initials'] ? companyProfile['company-initials'] : '')} | Product Cart</title>
      </Helmet>
      <div className="main cart">
        <div className="page-content pt-7  pb-10">
          <Navigation activePath='cart' />
          <div className="container mt-7 mb-2">
            <div className="row">
              {cartItems.length > 0 ? (
                <>
                  <div className="col-lg-8 col-md-12 pr-lg-4">
                    <table className="shop-table cart-table">
                      <thead>
                        <tr>
                          <th>
                            <span>Product</span>
                          </th>
                          <th></th>
                          <th>
                            <span>Price</span>
                          </th>
                          <th>
                            <span>quantity</span>
                          </th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((item) => (
                          <tr key={"cart" + item.name}>
                            <td className="product-thumbnail">
                              <figure>
                                <ALink href={`/collections/${item.collection.collectionSlug}/products/${item.slug}`}>
                                  <img
                                    src={item.pictures[0].url}
                                    width="100"
                                    height="100"
                                    alt="product"
                                  />
                                </ALink>
                              </figure>
                            </td>
                            <td colSpan="1">
                              <div className="product-name-section">
                                <ALink href={`/collections/${item.collection.collectionSlug}/products/${item.slug}`}>
                                  <p className="mb-2 font-weight-bolder" >{item.name}</p>
                                </ALink>
                                {item.formFields.map((singleField) => (
                                  <p className="mt-0 mb-0 overflow-ellipse" >{singleField.label}: {singleField.value}</p>
                                ))}
                              </div>
                            </td>
                            <td className="product-subtotal">
                              <span className="amount">
                                &euro;{toDecimal(item.price)}
                              </span>
                            </td>

                            <td className="product-quantity">
                              <Quantity
                                qty={item.qty}
                                max={item.stock}
                                onChangeQty={(qty) => onChangeQty(item.productInstanceId, qty)}
                              />
                            </td>
                            <td className="product-price">
                              <span className="amount">
                                &euro;{toDecimal(item.price * item.qty)}
                              </span>
                            </td>
                            <td className="product-close">
                              <ALink
                                href="#"
                                className="product-remove"
                                title="Remove this product"
                                onClick={() => removeFromCart(item)}
                              >
                                <i className="fas fa-times"></i>
                              </ALink>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* <div className="cart-coupon-box mb-8">
                    <h4 className="title coupon-title text-uppercase ls-m">
                      Coupon Discount
                    </h4>
                    <input
                      type="text"
                      name="coupon_code"
                      className="input-text form-control text-grey ls-m mb-4"
                      id="coupon_code"
                      placeholder="Enter coupon code here..."
                    />
                    <button
                      type="submit"
                      className="btn btn-md btn-dark btn-rounded btn-outline"
                    >
                      Apply Coupon
                    </button>
                  </div> */}
                  </div>
                  <aside className="col-lg-4 sticky-sidebar-wrapper">
                    <div
                      className="sticky-sidebar"
                      data-sticky-options="{'bottom': 20}"
                    >
                      <div className="summary mb-4">
                        <h3 className="summary-title text-left">Cart Totals</h3>
                        <table className="shipping">
                          <tbody>
                            <tr className="summary-subtotal">
                              <td>
                                <h4 className="summary-subtitle">Subtotal</h4>
                              </td>
                              <td>
                                <p className="summary-subtotal-price">
                                  &euro;{toDecimal(getTotalPrice(cartItems))}
                                </p>
                              </td>
                            </tr>
                            {/* <tr className="sumnary-shipping shipping-row-last">
                            <td colSpan="2">
                              <h4 className="summary-subtitle">
                                Calculate Shipping
                              </h4>
                              <ul>
                                <li>
                                  <div className="custom-radio">
                                    <input
                                      type="radio"
                                      id="flat_rate"
                                      name="shipping"
                                      className="custom-control-input"
                                      defaultChecked
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="flat_rate"
                                    >
                                      Flat rate
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div className="custom-radio">
                                    <input
                                      type="radio"
                                      id="free-shipping"
                                      name="shipping"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="free-shipping"
                                    >
                                      Free shipping
                                    </label>
                                  </div>
                                </li>

                                <li>
                                  <div className="custom-radio">
                                    <input
                                      type="radio"
                                      id="local_pickup"
                                      name="shipping"
                                      className="custom-control-input"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="local_pickup"
                                    >
                                      Local pickup
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </td>
                          </tr> */}
                          </tbody>
                        </table>
                        {/* <div className="shipping-address">
                        <label>
                          Shipping to <strong>CA.</strong>
                        </label>
                        <div className="select-box">
                          <select
                            name="country"
                            className="form-control"
                            defaultValue="us"
                          >
                            <option value="us">United States (US)</option>
                            <option value="uk"> United Kingdom</option>
                            <option value="fr">France</option>
                            <option value="aus">Austria</option>
                          </select>
                        </div>
                        <div className="select-box">
                          <select
                            name="country"
                            className="form-control"
                            defaultValue="us"
                          >
                            <option value="us">California</option>
                            <option value="uk">Alaska</option>
                            <option value="fr">Delaware</option>
                            <option value="aus">Hawaii</option>
                          </select>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          name="code"
                          placeholder="Town / City"
                        />
                        <input
                          type="text"
                          className="form-control"
                          name="code"
                          placeholder="ZIP"
                        />
                        <ALink
                          href="#"
                          className="btn btn-md btn-dark btn-rounded btn-outline"
                        >
                          Update totals
                        </ALink>
                      </div> */}
                        <table className="total">
                          <tbody>
                            <tr className="summary-subtotal">
                              <td>
                                <h4 className="summary-subtitle">Total</h4>
                              </td>
                              <td>
                                <p className="summary-total-price ls-s">
                                  &euro;{toDecimal(getTotalPrice(cartItems))}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* <button
                          type="submit"
                          className="btn btn-dark btn-rounded btn-checkout"
                          onClick={handleClick}
                        >
                          Place Order
                        </button> */}
                        <div className=" cart-actions  pt-4 justify-content-center mb-2 border-bottom">
                            <ALink href="/" className="btn btn-dark btn-link ">Continue Shopping</ALink>
                        </div>
                        <ALink
                          href="checkout"
                          className="btn btn-dark btn-rounded btn-checkout"
                        >
                          Proceed to checkout
                        </ALink>

                      </div>
                    </div>
                  </aside>
                </>
              ) : (
                <div className="empty-cart text-center">
                  <p>Your cart is currently empty.</p>
                  <i className="cart-empty d-icon-bag"></i>
                  <p className="return-to-shop mb-0">
                    <ALink
                      className="button wc-backward btn btn-dark btn-md"
                      href="/"
                    >
                      Return to shop
                    </ALink>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    cartList: state.cart.data ? state.cart.data : [],
  };
}

export default connect(mapStateToProps, {
  removeFromCart: cartActions.removeFromCart,
  updateCart: cartActions.updateCart,
})(React.memo(Cart));
