import React, { useState, useEffect } from 'react';
import ALink from '../custom-link';
import { toDecimal, getTotalPrice } from "../utils";
import { connect } from "react-redux";
import axios from 'axios';
import FullPageSpinner from '../spinner/Spinner';
import { Helmet } from 'react-helmet';
import Navigation from '../cartNavigation/navigation';
import GenerateInputFields from '../pages/GenerateInputFields';
import { getDataFromDatabase } from '../utils/dataBaseHelper';
import CheckoutFormInput from './checkoutFormInput';
const Checkout = (props) => {
  const { cartList } = props;
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);
  const [isFullPageSpinnerLoading, setFullPageSpinnerLoading] = useState(true);
  const [allCollections, setAllCollections] = useState([]);
  const [CheckoutFormMetaData, setCheckoutFormMetaData] = useState([]);
  const [backendUrlsResponse, setBackendUrlsResponse] = useState([]);
  const [isDeliveryFieldsVisible, setIsDeliveryFieldsVisible] = useState(false);
  const [companyProfile, setCompanyProfile] = useState([]);
  const [deliveryOptionsByCost, setDeliveryOptionsByCost] = useState([]);
  const fetchDataFromBackend = async () => {
    try {
      const [BackendUrlsResponse, companyProfileResponse] = await Promise.all([
        getDataFromDatabase('/database/urls/backendUrls.json'),
        getDataFromDatabase('/database/companyProfile/companyProfile.json'),
      ]);
      if (BackendUrlsResponse) {
        setBackendUrlsResponse(BackendUrlsResponse);
      }
      if (companyProfileResponse) {
        setCompanyProfile(companyProfileResponse);
      }
      if (BackendUrlsResponse && companyProfileResponse) {
        const response = await getDataFromDatabase('/database/dynamicFormMetaData/checkoutFormMetaData.json');
        if (response) {
          // setCheckoutFormMetaData(response);
          let allCollectionsResponse = await getDataFromDatabase('/database/search/allCollection.json');
          if (allCollectionsResponse) {
            // console.log('AllCollectionResponse=>', allCollectionsResponse);
            setAllCollections(allCollectionsResponse);
            let DummyResponse = updateDeliveryOptions(response, allCollectionsResponse)
            // console.log('DummyResponse==>', DummyResponse);
            setCheckoutFormMetaData(DummyResponse);
          }
        }
      }
      setFullPageSpinnerLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setFullPageSpinnerLoading(false)
    }
  };
  const updateDeliveryOptions = (checkOutFormMetaData, allCollectionsResponse) => {
    // console.log('checkOutFormMetaData==>', checkOutFormMetaData);
    // console.log('cartList==>', cartList);

    let cartListActiveOptions = [];
    let costByName = {};

    cartList.forEach((singleList) => {
      const collection = allCollectionsResponse.CollectionIndex.find(collection =>
        collection.collectionName === singleList.collection.collectionName
      );

      if (collection && collection.deliveryOptions) {
        collection.deliveryOptions.forEach(option => {
          cartListActiveOptions.push(option.type);
          costByName[option.type] = option.cost;
        });
      }
    });

    cartListActiveOptions = [...new Set(cartListActiveOptions)];
    // console.log('cartListActiveOptions==>', cartListActiveOptions);
    // console.log('costByName==>', costByName);
    setDeliveryOptionsByCost(costByName);
    checkOutFormMetaData.Delivery[0].options = cartListActiveOptions;
    checkOutFormMetaData.Delivery[0].costByName = costByName;
    return checkOutFormMetaData;
  };
  useEffect(() => {
    fetchDataFromBackend();
  }, [])
  const handlePlaceOrderSubmit = async (event) => {
    event.preventDefault();
    setFullPageSpinnerLoading(true);
    try {
      let lineItems = generateStripeLineItemFromCartProducts();
      console.log('LineItems==>', lineItems)
      lineItems = addShippingAddressToLineItems(lineItems);
      const checkOutFormNameVsValue = getCheckOutFormDetails();
      let checkOutURL = await getStripeCheckOutURL(lineItems, checkOutFormNameVsValue);
      if (checkOutURL != null) {
        window.location.href = checkOutURL;
      }
    } finally {
      setFullPageSpinnerLoading(false);
    }
  };
  function generateShippingAddressFormattedVersion(jsonObj) {
    return Object.entries(jsonObj)
      .map(([key, value]) => `${key}:${value}`)
      .join('|');
  }
  const addShippingAddressToLineItems = (lineItems) => {
    let formData = getCheckOutFormDetails();
    let shippingAddress = {};
    shippingAddress = {
      "First Name":formData.firstname,
      "Last Name":formData.lastname,
      "Shipping type": formData.shippingType,
      "Address line1": formData.address1,
      "Address line2": formData.address2,
      "City": formData.city,
      "State": formData.state,
      "ZipCode": formData.zipCode,
      "Country": formData.country,
    }
    let data = {
      "price_data": {
        "currency": "eur",
        "unit_amount": 0,
        "product_data": {
          "name": JSON.stringify({
            "productId": 'delivery',
            "productName": '',
            "collectionId": '',
            "collectionName": '',
            "customisations": [shippingAddress],
            "quantity": 1,
            "unitPrice": 0.00
          }),
          "description": " "
        }
      },
      "quantity": 1
    }
    lineItems.push(data);
    return lineItems;
  }
  const generateStripeLineItemFromCartProducts = () => {
    return cartList.map((item) => ({
      price_data: {
        currency: 'eur',
        unit_amount: (item.onePrice) * 100,
        product_data: {
          name: getProductName(item),
          description: ' '
        }
      },
      quantity: item.qty
    }));
  };

  const getProductName = (item) => {
    let customisations = {};
    item.formFields.map((singleField) => {
      customisations[singleField.label] = singleField.value
    })
    // console.log('Customisations=>', customisations);
    return JSON.stringify({

      "productId": item.slug,
      "productName": item.name,
      "collectionId": item.collection.collectionSlug,
      "collectionName": item.collection.collectionName,
      "customisations": [customisations],
      "quantity": item.qty,
      "unitPrice": (item.onePrice) * 100

    })
    // let formData = getCheckOutFormDetails()
    // const productName = item.name;
    // const customizations = item.formFields.map((field) => `${field.label} : ${field.value}`).join('|');
    // console.log('customizations=>', customizations);
    // return `productId:${item.slug} productName:${productName}|collectionId:${item.collection.collectionSlug}|collectionName:${item.collection.collectionName}|Customisation:[${customizations}]`;
  };

  const createCheckoutRequest = (lineItems, formData, shippingAddress) => {
    const url = backendUrlsResponse.checkOut.getCheckOutUrl;
    let collectionList = new Set();
    cartList.map((cartData) => {
      collectionList.add(cartData.collection.collectionName);
    })
    // console.log('deliveryOptionsByCost[formData.shippingType]==>', deliveryOptionsByCost[formData.shippingType]);
    let metaData = {
      'collections': JSON.stringify([...collectionList]),
      'shipping_address': JSON.stringify(shippingAddress),
      'fulfillment_status': 'UnFulfilled'
    }
    let orderPageURL = window.location.origin + backendUrlsResponse.checkOut.successURL;
    const headers = {
      'Content-Type': 'application/json',
      // 'success_url': window.location.origin + backendUrlsResponse.checkOut.successURL + '?session_id={CHECKOUT_SESSION_ID}',
      'success_url': `${backendUrlsResponse.successUrl}?session_id={CHECKOUT_SESSION_ID}&redirecturl=${orderPageURL}&tenant_id=${companyProfile.tenantId}`,
      'cancel_url': window.location.origin + backendUrlsResponse.checkOut.cancelURL,
      'tenant_id': companyProfile.tenantId,
    };
    return axios.post(url, {
      line_items: lineItems,
      email: formData.email,
      first_name: formData.firstname,
      last_name: formData.lastname,
      phone: formData.phone,
      // shipping_cost: deliveryOptionsByCost[formData.shippingType],
      meta_data: metaData
    }, { headers });
  };
  const getShippingAddFromFormData = (formData) => {
    let shippingAddress = {}
    CheckoutFormMetaData.Delivery.map((deliveryOption) => {
      shippingAddress[deliveryOption.id] = formData[deliveryOption.id]
    })
    CheckoutFormMetaData['Shipping Details'].map((shippingDetail) => {
      shippingAddress[shippingDetail.id] = formData[shippingDetail.id]
    })
    if (shippingAddress.shippingType === 'Home delivery') {
      return shippingAddress
    }
    return { "Shipping Type": shippingAddress.shippingType };
  }
  const getStripeCheckOutURL = async (lineItems, formData) => {
    // console.log('formData', formData);
    try {
      let shippingAddress = getShippingAddFromFormData(formData)
      const response = await createCheckoutRequest(lineItems, formData, shippingAddress);
      // console.log('response', response);
      if (response.data.checkoutUrl) {
        resetFormData();
        setIsTermsAndConditionsChecked(false)
        return response.data.checkoutUrl;
      } else {
        console.error('No checkout URL found in the response');
        return null;
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const resetFormData = () => {
    const formElement = document.getElementById('çheckOutForm');
    formElement.reset();
  }
  const getCheckOutFormDetails = () => {
    const formElement = document.getElementById('çheckOutForm');
    const formData = new FormData(formElement);
    return Object.fromEntries(formData.entries());
  }
  return (
    <>
      <Helmet>
        <title>{String(companyProfile['company-initials'] ? companyProfile['company-initials'] : '')} | CheckOut</title>
      </Helmet>
      <div className=' container mt-7 page-content pt-2 pb-1  checkout mb-2'>
        {isFullPageSpinnerLoading ? <FullPageSpinner /> : null}
        <Navigation activePath='checkout' />
        {
          cartList.length > 0 ?

            <form action="#" className="form" onSubmit={handlePlaceOrderSubmit} id='çheckOutForm'>
              <div className="row">
                <div className="col-lg-7 mb-6 mb-lg-0 pr-lg-4">
                  {Object.entries(CheckoutFormMetaData).map(([sectionTitle, fields]) => {
                    if (sectionTitle === 'Shipping Details' && isDeliveryFieldsVisible) {
                      return (
                        <div key={sectionTitle}>
                          <h3 className="title title-simple text-left text-uppercase">
                            {sectionTitle}
                          </h3>
                          <div>
                            <CheckoutFormInput
                              formFields={fields}
                              marginBetweenFiled={'0'}
                              formID={'checkoutForm'}
                              setIsDeliveryFieldsVisible={setIsDeliveryFieldsVisible}
                              allCollections={allCollections}
                            />
                          </div>
                        </div>
                      );
                    } else if (sectionTitle !== 'Shipping Details') {
                      return (
                        <div key={sectionTitle}>
                          <h3 className="title title-simple text-left text-uppercase">
                            {sectionTitle}
                          </h3>
                          <div>
                            <CheckoutFormInput
                              formFields={fields}
                              marginBetweenFiled={'0'}
                              formID={'checkoutForm'}
                              setIsDeliveryFieldsVisible={setIsDeliveryFieldsVisible}
                              allCollections={allCollections}
                            />
                          </div>
                        </div>
                      );
                    }
                    return null; // Return null for sections that don't meet the conditions
                  })}
                </div>


                <aside className="col-lg-5 sticky-sidebar-wrapper">
                  <div
                    className="sticky-sidebar mt-1"
                    data-sticky-options="{'bottom': 50}"
                  >
                    <div className="summary pt-5" >
                      <table>
                        <tr>
                          <td> <h3 className="title title-simple text-left text-uppercase">Total</h3></td>
                          <td><h3 className="title title-simple text-left text-uppercase" style={{ textAlignLast: "right" }}>&euro;{toDecimal(getTotalPrice(cartList))}</h3></td>

                        </tr>
                      </table>
                      <div className="mb-3">
                        <div className="form-checkbox mt-4 mb-5">
                          <input
                            type="checkbox"
                            className="custom-checkbox"
                            id="terms-condition"
                            name="terms-condition"
                            checked={isTermsAndConditionsChecked}
                            onChange={() => {
                              isTermsAndConditionsChecked ? setIsTermsAndConditionsChecked(false) : setIsTermsAndConditionsChecked(true)
                            }}
                          />
                          <label
                            className="form-control-label"
                            htmlFor="terms-condition"
                          >
                            I have read and agree to the website{" "}
                            <ALink href="#">terms and conditions </ALink>*
                          </label>
                        </div>
                        <button type="submit" className={`${isTermsAndConditionsChecked ? 'btn btn-dark btn-rounded btn-order' : "btn btn-dark btn-rounded btn-order btn-disabled"}`}>
                          Place Order
                        </button>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </form >
            : <>
              <div className="empty-cart text-center">
                <p>No item for checkout.</p>
                <i className="cart-empty d-icon-bag"></i>
                <p className="return-to-shop mb-0">
                  <ALink
                    className="button wc-backward btn btn-dark btn-md"
                    href="/"
                  >
                    Return to shop
                  </ALink>
                </p>
              </div>
            </>
        }

      </div >
    </>
  );
};
function mapStateToProps(state) {
  return {
    cartList: state.cart.data ? state.cart.data : [],
  };
}

export default connect(mapStateToProps)(Checkout);
