import React from 'react';
import Helmet from 'react-helmet';
import ALink from '../custom-link';
import { connect } from 'react-redux';
import { cartActions } from '../store/cart';
import { toDecimal, getTotalPrice } from '../utils';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from 'axios';
import Navigation from '../cartNavigation/navigation';
import Spinner from '../spinner/Spinner';
import { getDataFromDatabase } from '../utils/dataBaseHelper';
import { pdf } from '@react-pdf/renderer';

function Order(props) {
    const { cartList, removeFromCart } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const [paymentIntentResponse, setPaymentIntentResponse] = useState();
    const [checkoutSessionResponse, setCheckoutSessionResponse] = useState();
    const [formattedOrderLineItems, setFormattedOrderLineItems] = useState();
    const [shippingAddress, setShippingAddress] = useState();
    // const [promoCode, setPromoCode] = useState();
    const [isFullPageSpinnerLoading, setFullPageSpinnerLoading] = useState(true);
    const [companyProfile, setCompanyProfile] = useState([]);
    const [backendUrls, setBackendUrls] = useState([]);
    useEffect(() => {
        let companyProfile = null;
        let backendUrls = null;
        const fetchInitialData = async () => {
            try {
                const [companyProfileResponse, backendUrlsResponse] = await Promise.all([
                    getDataFromDatabase('/database/companyProfile/companyProfile.json'),
                    getDataFromDatabase('/database/urls/backendUrls.json')
                ]);

                if (companyProfileResponse) {
                    companyProfile = companyProfileResponse;
                    setCompanyProfile(companyProfileResponse);
                }
                if (backendUrlsResponse) {
                    backendUrls = backendUrlsResponse;
                    setBackendUrls(backendUrlsResponse);
                }

                const sessionId = getCheckOutSessionIdFromURL();
                if (sessionId && backendUrls && companyProfile) {
                    await getPaymentIntentDetails(sessionId, backendUrls.order.getTransactionsDetail, companyProfile.tenantId);
                } else {
                    console.error('Session ID or backend URLs not found');
                    navigate('/pages/404')
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
                navigate('/pages/404')
            } finally {
                clearLocalStorage();
            }
        };
        const getPaymentIntentDetails = async (sessionId, url, tenantId) => {
            try {
                const response = await axios.post(url, {}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'session_id': sessionId,
                        'tenant_id': tenantId,
                    }
                });
                setShippingAddress(JSON.parse(response.data.paymentIntent.metadata.shipping_address));
                console.log('response from PatymentIntnet==>', response.data.paymentIntent.metadata.shipping_address);
                setPaymentIntentResponse(response.data.paymentIntent);
                setCheckoutSessionResponse(response.data.session);

                if (companyProfile && backendUrls) {
                    await getOrderLineItems(response.data.paymentIntent.id);
                } else {
                    console.error('companyProfile or backendUrls is null');
                    navigate('/pages/404')
                }

                setFullPageSpinnerLoading(false);
            } catch (error) {
                console.error('Error making POST request:', error);
                navigate('/pages/404')
            }
        };
        const getOrderLineItems = async (paymentIntentId) => {
            try {
                const headers = {
                    'Content-Type': 'application/json',
                    'tenant_id': companyProfile.tenantId,
                };
                const response = await axios.post(
                    backendUrls.order.GetStripeFormattedLineItems,
                    { 'payment_intent_id': paymentIntentId },
                    { headers }
                );
                // console.log('formattedOrderLineItems=>', response.data);
                let formattedOrderLineItems = await getImageDataOnFormattedUrl(response.data)
                // let couponId = await generateCouponIfLineItemsHaveDiscountCouponsProduct(formattedOrderLineItems);
                setFormattedOrderLineItems(formattedOrderLineItems);
            } catch (error) {
                console.error('Error making POST request:', error);
            }
        };
        const getCheckOutSessionIdFromURL = () => {
            const queryParams = new URLSearchParams(window.location.search);
            return queryParams.get('session_id');
        };
        const clearLocalStorage = () => {
            cartList.forEach((cartItems) => {
                removeFromCart(cartItems);
            });
        };
        fetchInitialData();
    }, []);
    // const generateCouponIfLineItemsHaveDiscountCouponsProduct = async (formattedOrderLineItems) => {
    //     try {
    //         for (const singleLineItems of formattedOrderLineItems) {
    //             if (singleLineItems.productName.includes('Promo Code')) {
    //                 const response = await axios.post('https://wbrcu6qgnl.execute-api.us-east-1.amazonaws.com/createCoupon', {
    //                     "name": singleLineItems.customisations[0].value,
    //                     "discountPrice": 20
    //                 });
    //                 console.log('Response:', response.data);Shipping Address

    //                 setPromoCode(response.data)
    //                 return response.data.id;
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error creating coupon:', error);
    //     }
    // }

    const getFormattedDate = (timestamp) => {

        const date = new Date(timestamp * 1000);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        return formattedDate

    }
    const getImageDataOnFormattedUrl = async (formattedOrderLineItems) => {
        for (const lineItem of formattedOrderLineItems) {
            let productName = lineItem.productName.split(':')[1];
            // console.log('productName =>', productName);
            let productDetails = await getProductImageUrl(productName);
            console.log('imageUrl =>', productDetails);
            lineItem.productDetails = productDetails;
        }
        console.log('formattedOrderLineItems==>With Image', formattedOrderLineItems);
        return formattedOrderLineItems
    };

    const getProductImageUrl = async (targetProductName) => {
        let productDetails = {};
        try {
            const [allProductsResponse] = await Promise.all([
                getDataFromDatabase('/database/search/allProduct.json'),
            ]);
            if (allProductsResponse) {
                // console.log('All Products Response:', allProductsResponse);
                const targetProduct = allProductsResponse.ProductIndex.find(
                    (product) => product.productName === targetProductName
                );
                // console.log('targetProduct====>', targetProduct)
                if (targetProduct) {
                    // console.log('Target Product Slug:', targetProduct.productSlug);
                    const [productDetailsResponse] = await Promise.all([
                        getDataFromDatabase(`/database/products/${targetProduct.productSlug}.json`),
                    ]);

                    // console.log('Product Details Response:', productDetailsResponse);
                    if (productDetailsResponse) {
                        productDetails.productFaceImage = productDetailsResponse.productFaceImage.url;
                        productDetails.productSlug = productDetailsResponse.slug;
                        productDetails.collectionSlug = productDetailsResponse.collection.collectionSlug;
                        // console.log('Image URL:', imageUrl);
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching product image URL:', error);
        }

        return productDetails;
    };

    if (!companyProfile) {
        return null;
    }
    return (
        <div className="main cart ">
            {isFullPageSpinnerLoading ? <Spinner /> :
                <>
                    <Helmet>
                        <title>{String(companyProfile['company-initials'] ? companyProfile['company-initials'] : '')} | Product Cart</title>
                    </Helmet>

                    <div className="page-content pt-7  pb-10">
                        <div className="container mt-7 mb-2">
                            <div className='order hide-on-print'>
                                <div className="order-message mr-auto ml-auto">
                                    <div className="icon-box d-inline-flex align-items-center">
                                        <div className="icon-box-icon mb-0">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" enableBackground="new 0 0 50 50" xmlSpace="preserve">
                                                <g>
                                                    <path fill="none" strokeWidth="3" strokeLinecap="round" strokeLinejoin="bevel" strokeMiterlimit="10" d="
                                            M33.3,3.9c-2.7-1.1-5.6-1.8-8.7-1.8c-12.3,0-22.4,10-22.4,22.4c0,12.3,10,22.4,22.4,22.4c12.3,0,22.4-10,22.4-22.4
                                            c0-0.7,0-1.4-0.1-2.1"></path>
                                                    <polyline fill="none" strokeWidth="4" strokeLinecap="round" strokeLinejoin="bevel" strokeMiterlimit="10" points="
                                            48,6.9 24.4,29.8 17.2,22.3 	"></polyline>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="icon-box-content text-left">
                                            <h5 className="icon-box-title font-weight-bold lh-1 mb-1">Thank You!</h5>
                                            <p className="lh-1 ls-m">Your order has been received</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="order-results">
                                    <div className="overview-item ">
                                        <span>Order number:</span>
                                        <strong className=''>{paymentIntentResponse ? paymentIntentResponse.id : ''}</strong>
                                    </div>
                                    <div className="overview-item">
                                        <span>Status:</span>
                                        <strong>{checkoutSessionResponse ? checkoutSessionResponse.payment_status : ''}</strong>
                                    </div>
                                    <div className="overview-item">
                                        <span>Date:</span>
                                        <strong>{getFormattedDate(checkoutSessionResponse ? checkoutSessionResponse.created : '')}</strong>
                                    </div>
                                    <div className="overview-item">
                                        <span>Email:</span>
                                        <strong className=''>{checkoutSessionResponse ? checkoutSessionResponse.customer_details.email : ''}</strong>
                                    </div>
                                    <div className="overview-item">
                                        <span>Total:</span>
                                        <strong>&euro;{checkoutSessionResponse ? toDecimal((checkoutSessionResponse.amount_total) / 100) : ''}</strong>
                                    </div>
                                </div>
                            </div>

                            <h2 className="title title-simple text-left pt-4 font-weight-bold text-uppercase">Order Details</h2>
                            <div className="row">
                                {formattedOrderLineItems.length > 0 ? (
                                    <>
                                        <div className="col-lg-8 col-md-12 pr-lg-4">
                                            <table className="shop-table cart-table">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th></th>
                                                        <th>Quantity</th>
                                                        <th>Unit Price</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {formattedOrderLineItems.map((singleLineItem) => (
                                                        singleLineItem.productId !== 'delivery' ?
                                                            <tr key={"cart" + singleLineItem.productName}>
                                                                <td className="product-thumbnail">
                                                                    <figure>
                                                                        <ALink href={`/collections/${singleLineItem.collectionId}/products/${singleLineItem.productId}`}>
                                                                            <img
                                                                                src={`/database/images/products/${singleLineItem.productId}/1.png`}
                                                                                width="100"
                                                                                height="100"
                                                                                alt="product"
                                                                            />
                                                                        </ALink>
                                                                    </figure>
                                                                </td>
                                                                <td colSpan="1">
                                                                    <div className="product-name-section">
                                                                        <ALink href={`/collections/${singleLineItem.collectionId}/products/${singleLineItem.productId}`}>
                                                                            <p className="mb-2 font-weight-bolder" >{singleLineItem.productName.split(':')[1]}</p>
                                                                        </ALink>
                                                                        {singleLineItem.customisations.flatMap((customisation, index) =>
                                                                            Object.entries(customisation).map(([key, value]) => (
                                                                                <p key={`${index}-${key}`} className="mt-0 mb-0 overflow-ellipse">
                                                                                    {key} : <span className="font-weight-normal">{value}</span>
                                                                                </p>
                                                                            ))
                                                                        )}

                                                                        {/* <div className='font-weight-bold'>Quantity : <span className='font-weight-normal'>{singleLineItem.quantity}</span></div>
                                                                    <div className='font-weight-bold'>Unit Price : <span className='font-weight-normal'>{toDecimal(singleLineItem.unitPrice / 100)}</span></div> */}
                                                                    </div>
                                                                </td>
                                                                <td className="product-subtotal">
                                                                    {singleLineItem.quantity}
                                                                </td>
                                                                <td className="product-quantity">
                                                                &euro;{toDecimal(singleLineItem.unitPrice / 100)}
                                                                </td>
                                                                <td className="product-price">
                                                                    <span className="amount">
                                                                        &euro;{toDecimal((singleLineItem.unitPrice * singleLineItem.quantity) / 100)}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            : null
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="row">
                                                {
                                                    shippingAddress ? (
                                                        <>
                                                            <h2 className="title title-simple text-left pt-10 mb-2">Shipping Address</h2>
                                                            <div className="main order address-info ">
                                                                <p className="address-detail pb-2">
                                                                    {Object.entries(shippingAddress).map(([key, value]) => (
                                                                        <span key={key}>
                                                                            {
                                                                                value.includes('Home Delivery') ? <strong>Delivered at</strong> : null
                                                                            }
                                                                            {value}<br /></span>
                                                                    ))}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ) : null

                                                }
                                                {/* {
                                                    promoCode ? (
                                                        <>
                                                            <h2 className="title title-simple text-left  mb-2">Promo Code Details</h2>
                                                            <div className="main order address-info ">
                                                                <p className="address-detail pb-2">
                                                                    <span key={promoCode}> <strong>Promo Code :</strong>{promoCode.id}<br /></span>
                                                                    <span key={promoCode}> <strong>Coupon Code :</strong>{promoCode.coupon.id}<br /></span>
                                                                </p>
                                                            </div>
                                                        </>
                                                    ) : null

                                                } */}
                                            </div>

                                        </div>
                                        <aside className="col-lg-4 sticky-sidebar-wrapper">
                                            <div
                                                className="sticky-sidebar"
                                                data-sticky-options="{'bottom': 20}"
                                            >
                                                <div className="summary mb-4">
                                                    <h3 className="summary-title text-left">Order Totals</h3>
                                                    <table className="shipping">
                                                        <tbody>
                                                            <tr className="summary-subtotal">
                                                                <td>
                                                                    <h4 className="summary-subtitle">Subtotal</h4>
                                                                </td>
                                                                <td>
                                                                    <p className="summary-subtotal-price">
                                                                        &euro;{checkoutSessionResponse ? toDecimal(checkoutSessionResponse.amount_subtotal / 100) : ''}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table className="total">
                                                        <tbody>
                                                            <tr className="summary-subtotal">
                                                                <td>
                                                                    <h4 className="summary-subtitle">Total</h4>
                                                                </td>
                                                                <td>
                                                                    <p className="summary-total-price ls-s">
                                                                        &euro;{checkoutSessionResponse ? toDecimal(checkoutSessionResponse.amount_total / 100) : ''}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className=" cart-actions  pt-4 justify-content-center mb-2 border-bottom">
                                                        <ALink href="/" className="btn btn-dark btn-link ">Continue Shopping</ALink>
                                                    </div>
                                                </div>
                                            </div>
                                        </aside>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>

                </>
            }
        </div>
    );
}
function mapStateToProps(state) {
    return {
        cartList: state.cart.data,
    };
}
export default connect(mapStateToProps, {
    removeFromCart: cartActions.removeFromCart,
})(Order);