import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SingleColumnTabView from './Tabs/SingleColumnTabView';
import ThreeColumnImageView from './Tabs/ThreeColumnImageTabView'
import ThreeColumnTabView from './Tabs/ThreeColumnTabView';
import FourColumnImageTabView from './Tabs/FourColumnImageTabView';
import SinglePropertyTabView from './Tabs/SinglePropertyTabView';
import axios from 'axios';
import { getDataFromDatabase } from '../utils/dataBaseHelper';
function MainMenu() {
    const Pathname = useLocation().pathname;
    const [headerMenuItems, setHeaderMenuItems] = useState([]);
    const [backendUrlsResponse, setBackendUrlsResponse] = useState([]);
    useEffect(() => {
        const fetchDataFromDatabase = async () => {
            const headerMenuItems = await getDataFromDatabase('/database/headersTabs/headerMenuItems.json');
            if (headerMenuItems) {
                setHeaderMenuItems(headerMenuItems);
            }
        };
        fetchDataFromDatabase();
    }, [])
    return (
        <nav className="main-nav">
            <ul className="menu menu-active-underline">
                {
                    headerMenuItems ? headerMenuItems.map((HeaderMenuItem) => (
                        HeaderMenuItem.tabType === 'SinglePropertyTabView' && HeaderMenuItem.isActive ?
                            <SinglePropertyTabView
                                Pathname={Pathname}
                                HeaderMenuItem={HeaderMenuItem}
                            />
                            :
                            HeaderMenuItem.tabType === 'SingleColumnTabView' && HeaderMenuItem.isActive ?
                                <SingleColumnTabView
                                    Pathname={Pathname}
                                    HeaderMenuItem={HeaderMenuItem}
                                />
                                :
                                HeaderMenuItem.tabType === 'ThreeColumnImageView' && HeaderMenuItem.isActive ?
                                    <ThreeColumnImageView
                                        Pathname={Pathname}
                                        HeaderMenuItem={HeaderMenuItem}
                                    />
                                    : HeaderMenuItem.tabType === 'ThreeColumnTabView' && HeaderMenuItem.isActive ?
                                        <ThreeColumnTabView
                                            Pathname={Pathname}
                                            HeaderMenuItem={HeaderMenuItem}
                                        />
                                        : HeaderMenuItem.tabType === 'FourColumnImageTabView' && HeaderMenuItem.isActive ?
                                            <FourColumnImageTabView
                                                Pathname={Pathname}
                                                HeaderMenuItem={HeaderMenuItem}
                                            />
                                            : ''
                    )) : ''
                }
            </ul>
        </nav >
    )
}

export default MainMenu;