import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ALink from '../custom-link'
import CartMenu from '../cart/cart-menu'
import MainMenu from '../menu/main-menu'
import SearchBox from '../childCmp/search'

import { headerBorderRemoveList } from '../utils/data/menu'
import axios from 'axios'
import { getDataFromDatabase } from '../utils/dataBaseHelper'
export default function Header(props) {
    const [companyProfile, setCompanyProfile] = useState(null);
    useEffect(() => {
        const fetchCompanyProfile = async () => {
            const profileData = await getDataFromDatabase('/database/companyProfile/companyProfile.json');
            if (profileData) {
                setCompanyProfile(profileData);
            }
        };
        fetchCompanyProfile();
    }, []);

    if (!companyProfile) {
        return null;
    }

    const showMobileMenu = () => {
        document.querySelector('body').classList.add('mmenu-active');
    }

    return (
        <header className="header hide-on-print">
            {/* <div className="header-top">
                <div className="container">
                    <div className="header-left">
                        <p className="welcome-msg">Welcome to Riode store message or remove it!</p>
                    </div>
                    <div className="header-right">
                        <div className="dropdown">
                            <ALink href="#">USD</ALink>
                            <ul className="dropdown-box">
                                <li><ALink href="#">USD</ALink></li>
                                <li><ALink href="#">EUR</ALink></li>
                            </ul>
                        </div>

                        <div className="dropdown ml-5">
                            <ALink href="#">ENG</ALink>
                            <ul className="dropdown-box">
                                <li>
                                    <ALink href="#">ENG</ALink>
                                </li>
                                <li>
                                    <ALink href="#">FRH</ALink>
                                </li>
                            </ul>
                        </div>

                        <span className="divider"></span>
                        <ALink href="/pages/contact-us" className="contact d-lg-show"><i className="d-icon-map"></i>Contact</ALink>
                        <ALink href="#" className="help d-lg-show"><i className="d-icon-info"></i> Need Help</ALink>
                        <LoginModal /> 
                    </div>
                </div>
            </div> */}

            <div className="header-middle sticky-header fix-top sticky-content">
                <div className="container">
                    <div className="header-left">
                        <ALink href="#" className="mobile-menu-toggle" onClick={showMobileMenu}>
                            <i className="d-icon-bars2"></i>
                        </ALink>

                        <ALink href="/" className="logo">
                            <img src={companyProfile.header.logo} alt={companyProfile.header['alt-text']} width={companyProfile.header.size.width} height={companyProfile.header.size.height} />
                        </ALink>
                        {/* <SearchBox /> */}
                    </div>

                    <div className="header-right">
                        {
                            companyProfile.phone.length > 10 ?
                                <>
                                    <ALink href={`tel:${companyProfile.phone}`} className="icon-box icon-box-side">
                                        <div className="icon-box-icon mr-0 mr-lg-2">
                                            <i className="d-icon-phone"></i>
                                        </div>
                                        <div className="icon-box-content d-lg-show">
                                            <h4 className="icon-box-title">Call Us Now:</h4>
                                            <p>{companyProfile.phone}</p>
                                        </div>
                                    </ALink>
                                    <span className="divider"></span>
                                </>
                                : null
                        }

                        <CartMenu />
                    </div>
                </div>
            </div>

            <div className="header-bottom d-lg-show">
                <div className="container">
                    <div className="header-left">
                        <MainMenu />

                    </div>


                    {/* <div className="header-right">
                        <ALink href="#"><i className="d-icon-card"></i>Special Offers</ALink>
                        <a href="https://d-themes.com/buynow/riodereact" className="ml-6">You are viewing as a buyer</a>
                    </div> */}
                </div>
            </div>

        </header >
    );
}

