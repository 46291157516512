import { useEffect } from "react";
import ALink from "../custom-link";
import banner from './product-list/media/banner.jpg'
export default function ShopBanner( props ) {
    const { subTitle = '', title = "", current = ""  , bannerImageUrl } = props;
    useEffect(()=>{
        console.log('BannerImageURl',bannerImageUrl);
    })
    return (
        <div className="page-header" style={ { backgroundImage: `url(${process.env.PUBLIC_URL}${bannerImageUrl}`, backgroundColor: "" } }>
            {
                subTitle ? <h3 className="page-subtitle text-dark">{ subTitle }</h3> : ''
            }
            {
                title ? <h1 className="page-title text-light">{ title }</h1> : ''
            }
            <ul className="breadcrumb">
                <li><ALink href="/"><i className="d-icon-home"></i></ALink></li>
                <li className="delimiter text-dark">/</li>
                <li className="text-dark">{ current }</li>
            </ul>
        </div>
    )
}