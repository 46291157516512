import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ALink from '../custom-link';
import Card from '../utils/accordian/card'; // Fixed the path

import SearchForm from '../childCmp/search';
import { getDataFromDatabase } from '../utils/dataBaseHelper';

function MobileMenu(props) {
    const [search, setSearch] = useState("");
    const [headerMenuItems, setHeaderMenuItems] = useState([]);
    const router = useLocation();

    useEffect(() => {
        const fetchDataFromDatabase = async () => {
            try {
                const headerMenuItems = await getDataFromDatabase('/database/headersTabs/headerMenuItems.json');
                if (headerMenuItems) {
                    console.log('HeaderMenuItems', headerMenuItems);
                    setHeaderMenuItems(headerMenuItems);
                }
            } catch (error) {
                console.error('Failed to fetch header menu items:', error);
            }
        };

        fetchDataFromDatabase();
        window.addEventListener('resize', hideMobileMenuHandler);
        document.querySelector("body").addEventListener("click", onBodyClick);

        return () => {
            window.removeEventListener('resize', hideMobileMenuHandler);
            document.querySelector("body").removeEventListener("click", onBodyClick);
        };
    }, []);

    const hideMobileMenuHandler = () => {
        if (window.innerWidth > 991) {
            document.querySelector('body').classList.remove('mmenu-active');
        }
    };

    const hideMobileMenu = () => {
        document.querySelector('body').classList.remove('mmenu-active');
    };

    function onSearchChange(e) {
        setSearch(e.target.value);
    }

    function onBodyClick(e) {
        if (e.target.closest('.header-search')) {
            e.target.closest('.header-search').classList.add('show-results');
            return;
        }

        document.querySelector('.header-search.show')?.classList.remove('show');
        document.querySelector('.header-search.show-results')?.classList.remove('show-results');
    }

    function onSubmitSearchForm(e) {
        e.preventDefault();
        router.push({
            pathname: '/shop',
            query: {
                search: search,
            },
        });
    }
    const [isHovered, setIsHovered] = useState(false); // State to manage hover


    const handleMouseEnter = () => {
        setIsHovered(true); // Set hover state to true
    };

    const handleMouseLeave = () => {
        setIsHovered(false); // Set hover state to false
    };
    return (
        <div className="mobile-menu-wrapper">
            <div className="mobile-menu-overlay" onClick={hideMobileMenu}></div>

            <ALink className="mobile-menu-close" href="#" onClick={hideMobileMenu}>
                <i className="d-icon-times"></i>
            </ALink>
            <div className="mobile-menu-container scrollable">
                {/* <SearchForm SearchBarForMobile={true} /> */}
                <ul className="mobile-menu mmenu-anim">
                    {headerMenuItems && headerMenuItems.length > 0 ? (
                        headerMenuItems.map((singleItem, index) =>
                            singleItem.isActive ?
                                singleItem.tabType === "SinglePropertyTabView" ? (
                                    <li key={index} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                        <ALink href={singleItem.url}>{singleItem.label}</ALink>
                                    </li>
                                ) : (
                                    <li>
                                        <Card key={index} title={singleItem.label} type="mobile" >
                                            <ul>
                                                {Array.isArray(singleItem.subItems) && singleItem.subItems.length > 0 ? (
                                                    singleItem.subItems.map((singleSubItem, subIndex) => (
                                                        <li key={`${singleSubItem.title}-${subIndex}`}>
                                                            <ALink href={`/${singleSubItem.url}`}>
                                                                {singleSubItem.title}
                                                            </ALink>
                                                        </li>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </ul>
                                        </Card>
                                    </li>
                                ) : null
                        )
                    ) : (
                        null
                    )}
                </ul>
            </div>
        </div>
    );
}

export default React.memo(MobileMenu);
