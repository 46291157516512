import React, { memo } from "react";

function GenerateInputFields(props) {
  const { formFields , marginBetweenFiled=4 } = props;

  return (
    <div className="row mb-2">
      {formFields.map((field, index) => (
        <div className={`col-${field.area} mb-${marginBetweenFiled}`} key={field.id}>
          {field.type === 'text' || field.type === 'tel' || field.type === 'number' || field.type === 'email' ? (
            <>
              {field.showLabel ? <label htmlFor={field.name}>{field.label} {field.isRequired ? "*" : null}</label> : null}
              <input
                className="form-control"
                name={field.name}
                id={field.id}
                type={field.type}
                required={field.isRequired}
                maxLength={field.maxLength}
                placeholder={`${field.showPlaceHolder ? field.placeholder : ''} ${field.isRequired && field.showPlaceHolder ? '*' : ''}`}
              />
            </>
          ) : field.type === 'pickList' ?
            <>
              {field.showLabel ? <label htmlFor={field.name}>{field.label} {field.isRequired ? "*" : null}</label> : null}
              <select
                id={field.id}
                className='form-control'
                name={field.name}
                defaultValue={field.value}
                required={field.isRequired}>
                <option value='' disabled>
                  {field.placeholder}
                </option>
                {field.options.map((entry, index) => (
                  <option
                    key={index}
                    value={entry}>
                    {entry}
                  </option>
                ))}
              </select>
            </>
            :
            field.type === 'textarea' ?
              <>
                {field.showLabel ? <label htmlFor={field.name}>{field.label} {field.isRequired ? "*" : null}</label> : null}
                <textarea
                  className="form-control"
                  required={field.isRequired}
                  id={field.id}
                  name={field.name}
                  placeholder={`${field.showPlaceHolder ? field.placeholder : ''} ${field.isRequired && field.showPlaceHolder ? '*' : ''}`}
                ></textarea>
              </>
              : null}
        </div>
      ))}
    </div>
  );
}

export default memo(GenerateInputFields);
