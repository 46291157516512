import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { store } from './components/store';
import { Provider } from 'react-redux';
import Layout from './components/layout';
import './public/sass/style.scss'
import Error404 from './components/pages/Error404'
import HomePage from './components/homeContent/Home';
import ProductHome from './components/products/productDetailPages/productHome';
import CollectionView from './components/categories/OffCanvusFilter/CollectionView';
import Cart from './components/cart/cart';
import Checkout from './components/checkout/checkout';
import Order from './components/Order/order';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout children={<HomePage />} />} />
            <Route path="products/:productSlug" element={<Layout children={<ProductHome />} />} />
            <Route path="collections/:collection-slug/products/:productSlug" element={<Layout children={<ProductHome />} />} />
            <Route path="pages/404" element={<Layout children={<Error404 />} />} />
            <Route path="pages/cart" element={<Layout children={<Cart />} />} />
            <Route path="pages/checkout" element={<Layout children={<Checkout />} />} />
            <Route path="pages/order?/:session_id" element={<Layout children={<Order />} />} />
            <Route path="*" element={<Layout children={<Error404 />} />} />
            <Route path="collections/:collectionSlug" element={<Layout children={<CollectionView />} />} />
          </Routes>
        </Router>
      </Provider>
  </React.StrictMode>
);
reportWebVitals();
