import React, { useEffect, useState } from "react";
import ALink from "../custom-link";
import { getDataFromDatabase } from "../utils/dataBaseHelper";
export default function Footer() {
    const [companyProfile, setCompanyProfile] = useState(null);
    useEffect(() => {
        const fetchCompanyProfile = async () => {
            const profileData = await getDataFromDatabase('/database/companyProfile/companyProfile.json');
            if (profileData) {
                setCompanyProfile(profileData);
            }
        };
        fetchCompanyProfile();
    }, []);

    if (!companyProfile) {
        return null; // Render nothing or a loading spinner while the data is loading
    }

    return (
        <footer className="footer hide-on-print">
            <div className="container">
                <div className="footer-middle">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="widget widget-about">
                                <ALink href="/" className="logo-footer mb-4">
                                    <img 
                                        src={companyProfile.footer.logo} 
                                        alt={companyProfile.footer["alt-text"]} 
                                        width={companyProfile.footer.size.width}
                                        height={companyProfile.footer.size.height} 
                                    />
                                </ALink>
                                <div className="widget-body">
                                    {/* <p className="ls-s">{companyProfile.description}</p> */}
                                    Ballaghaderreen GAA Club<br/><p>{companyProfile['address-line1']}<br />{companyProfile['address-line2']}<br />{companyProfile['address-line3']}<br /></p>
                                    <ALink href={`mailto:${companyProfile.email}`}>{companyProfile.email}</ALink>
                                </div>
                            </div>
                        </div>
                         <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-4 col-sm-4">
                                    <div className="widget">
                                        {/* <h4 className="widget-title">About Us</h4> */}
                                        <ul className="widget-body">
                                            {/* <li><ALink href="/pages/about-us">About Us</ALink></li>
                                            <li><ALink href="#">Order History</ALink></li>
                                            <li><ALink href="#">Returns</ALink></li>
                                            <li><ALink href="#">Custom Service</ALink></li> */}
                                            {/* <li><ALink href="/pages/terms-and-condition">Terms &amp; Condition</ALink></li> */}
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-sm-4">
                                    <div className="widget">
                                        <h4 className="widget-title">Customer Service</h4>
                                        <ul className="widget-body">
                                            <li><ALink href="#">Payment Methods</ALink></li>
                                            <li><ALink href="#">Money-back Guarantee!</ALink></li>
                                            <li><ALink href="#">Returns</ALink></li>
                                            <li><ALink href="#">Shipping</ALink></li>
                                            <li><ALink href="#">Terms and Conditions</ALink></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-4">
                                    <div className="widget">
                                        <h4 className="widget-title">My Account</h4>
                                        <ul className="widget-body">
                                            <li><ALink href="/pages/login">Sign In</ALink></li>
                                            <li><ALink href="/pages/cart">View Cart</ALink></li>
                                            <li><ALink href="/pages/wishlist">My Wishlist</ALink></li>
                                            <li><ALink href="#">Track My Order</ALink></li>
                                            <li><ALink href="#">Help</ALink></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div> 
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="footer-left">
                        {/* <figure className="payment">
                            <img src={'/database/images/footer/payment.png'} alt="payment" width="159" height="29" />
                        </figure> */}
                    </div>
                    <div className="footer-center">
                        <p className="copyright">
                            {companyProfile['company-initials']} | All Rights Reserved
                        </p>
                    </div>
                    <div className="footer-right">
                        <div className="social-links">
                            <ALink href={companyProfile['social-media']['facebook']} className="social-link social-facebook fab fa-facebook-f"></ALink>
                            <ALink href={companyProfile['social-media']['twitter']} className="social-link social-twitter fab fa-twitter"></ALink>
                            <ALink href={companyProfile['social-media']['linkedin']} className="social-link social-linkedin fab fa-linkedin-in"></ALink>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
