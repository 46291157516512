// Recommended image size 720 X 384 PX
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

// import { useQuery } from "@apollo/react-hooks";

// // Import Apollo Server and Query
// import withApollo from '../server/apollo';


// import Home Components
import NewsletterModal from './modals/NewsletterModal';
import IntroSection from './modals/IntroSection';
import BestCollection from './modals/best-collection';
import CategorySection from './modals/CategorySection'
import PromoSection from './modals/PromoSection';
import FeaturedCollection from './modals/FeaturedCollection';
import InstagramSection from './modals/instagram-section';
import HomeBlogsGrid from '../pages/blogs/mask/HomeBlogsGrid';
import AdvertisementCard from './modals/advertisement-card';
import ServiceBox from './modals/ServiceBox';
import { useLocation, useNavigate } from "react-router-dom";
// import Header from '../header/Heder';
import axios from 'axios';
import { getDataFromDatabase } from '../utils/dataBaseHelper';
function HomePage() {
    const navigate = useNavigate();
    const [companyProfile, setCompanyProfile] = useState(null);
    const [isFullPageSpinnerActive, setFullPageSpinnerActive] = useState(true);
    const [promotionMetaData, setPromotionMetaData] = useState(true);
    const [topCategories, setTopCategories] = useState(true);
    const [homePageIntroSectionConfigurations, setHomePageIntroSectionConfigurations] = useState(null);
    const [newLetterMetaData, setNewLetterMetaData] = useState(true);
    const [advertisementCardMetaData, setAdvertisementCardMetaData] = useState(true);
    const [serviceBoxMetaData, setServiceBoxMetaData] = useState({});
    useEffect(() => {
        const fetchProductAndProfileData = async () => {

            const [companyProfileResponse, homePageIntroSectionResponse, topCategoriesResponse, promotionResponse, newsLetterResponse, advertisementCardResponse, serviceBoxResponse] = await Promise.all([
                getDataFromDatabase('/database/companyProfile/companyProfile.json'),
                getDataFromDatabase('/database/componentsConfigurations/homePageIntroSection.json'),
                getDataFromDatabase('/database/componentsConfigurations/homePageCategoriesSections.json'),
                getDataFromDatabase('/database/componentsConfigurations/homePagePromoSection.json'),
                getDataFromDatabase('/database/componentsConfigurations/homePageNewsLetter.json'),
                getDataFromDatabase('/database/componentsConfigurations/homePageAdvertisementCard.json'),
                getDataFromDatabase('database/componentsConfigurations/homePageServiceBox.json')
            ]);

            if (companyProfileResponse) {
                setCompanyProfile(companyProfileResponse);
            }
            if (advertisementCardResponse) {
                setAdvertisementCardMetaData(advertisementCardResponse);
            }
            if (promotionResponse) {
                setPromotionMetaData(promotionResponse);
            }
            if (homePageIntroSectionResponse) {
                setHomePageIntroSectionConfigurations(homePageIntroSectionResponse);
            }
            if (topCategoriesResponse) {
                setTopCategories(topCategoriesResponse);
            }
            if (newsLetterResponse) {
                setNewLetterMetaData(newsLetterResponse);
            }
            if (serviceBoxResponse) {
                setServiceBoxMetaData(serviceBoxResponse)
            }
            setFullPageSpinnerActive(false);
        };
        fetchProductAndProfileData();
        // navigate('/collections/50-50-lotto')
    }, []);

    if (!companyProfile) {
        return null;
    }
    return (
        <div className="main home demo2-cls">
            <Helmet>
                <title>{String(companyProfile['company-initials'])} - Home</title>
            </Helmet>
            {/* <h1 className="d-none"> - Home</h1> */}
            <div className="page-content">
                <div className="container">
                    {/* <IntroSection config={homePageIntroSectionConfigurations} /> */}
                    {/* <AdvertisementCard config={advertisementCardMetaData} /> */}
                    {/* <BestCollection products={ Product.data } loading={ loading } /> */}
                    {/* <CategorySection config={topCategories} /> */}
                    {/* <PromoSection config={promotionMetaData} /> */}
                    <FeaturedCollection collectionSlug={{ "collectionSlug": '50-50-lotto' }} loading={true} />
                    {/* <ServiceBox config={serviceBoxMetaData} /> */}
                    {/* <InstagramSection /> */}
                    {/* <HomeBlogsGrid /> */}
                </div>
            </div>

            {/* <NewsletterModal config={newLetterMetaData} /> */}

        </div>
    )
}

export default HomePage